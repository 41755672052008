import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import ShButton from '@/components/Button';

export default {
  name: 'CloseButton',

  components: {
    FontAwesomeIcon,
    ShButton,
  },

  extends: {
    props: {
      size: {
        ...FontAwesomeIcon.props.size,
      },
    },
  },

  props: {
    label: {
      default: 'Remove',
      type: String,
    },
    shade: {
      default: 'dark',
      type: String,
      validator: value => ['dark', 'light'].indexOf(value) !== -1,
    },
  },

  computed: {
    classes() {
      const { $style, shade } = this;

      return [$style.button, $style[shade]];
    },
  },
};
