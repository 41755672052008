import Spinner from '@/components/Spinner';

export default {
  name: 'ShButton',

  components: {
    Spinner,
  },

  props: {
    disabled: {
      default: false,
      type: Boolean,
    },
    useLightSpinner: {
      default: false,
      type: Boolean,
    },
    waiting: {
      default: false,
      type: Boolean,
    },
  },

  methods: {
    handleClick(event) {
      if (!this.disabled && !this.waiting) {
        this.$emit('click', event);
      }
    },
  },
};
